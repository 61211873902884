<script setup>
/******************************************************************************
 * component: Header navigation
 *****************************************************************************/
import SvgIcon from "Components/SvgIcon/SvgIcon.vue";
import { router } from "@/router/index";
import { useNavigationStore } from "Stores/navigation";
const navigation = useNavigationStore();
const routes = router
    .getRoutes()
    .filter((route) => route.name !== "NotFound")
    .map((route) => {
        return {
            name: route.name,
            icon: route.meta.icon,
            title: route.meta.title,
        };
    });
</script>

<template>
    <nav>
        <router-link
            v-for="route in routes"
            :key="route.name"
            :to="{ name: route.name }"
            :class="navigation.getSectionStatus(route.name)"
            :aria-disabled="
                navigation.getSectionStatus(route.name) === 'locked'
            "
        >
            <svg-icon :name="route.icon" class="section-icon" />
            <span class="title">{{ route.title }}</span>
            <svg-icon
                v-if="navigation.getSectionStatus(route.name)"
                :name="navigation.getSectionStatus(route.name)"
                :class="navigation.getSectionStatus(route.name)"
                :size="1"
            />
        </router-link>
    </nav>
</template>

<style lang="scss" scoped>
nav {
    display: flex;

    border-top: get($client, "appBorder");
    gap: get($client, "navBarGap");

    > a {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        padding: #{get($sizes, "base") * 0.4} 0;
        gap: #{get($sizes, "base") * 0.5};
        flex-basis: 25%;

        background-color: get($client, "navBarLinkBg");
        color: get($client, "navBarLinkColor");

        text-decoration: none;

        transition: background-color get($timings, "fast") linear,
            color get($timings, "fast") linear;

        @include respond-to("medium") {
            padding: #{get($sizes, "base") * 0.625} 0;
        }

        &:not(.locked):hover {
            background-color: color.adjust(
                get($client, "navBarLinkBg"),
                $lightness: -10%
            );
        }

        // active section
        &.router-link-exact-active {
            background-color: get($client, "navBarLinkActiveBg");
            color: get($client, "navBarLinkActiveColor");

            &:hover {
                background-color: color.adjust(
                    get($client, "navBarLinkActiveBg"),
                    $lightness: -10%
                );
            }
        }

        // locked section
        &.locked {
            cursor: not-allowed;

            .title {
                opacity: 0.4;

                font-style: italic;
            }

            .section-icon {
                opacity: 0.4;
            }
        }

        // section title
        .title {
            display: none;

            @include respond-to("medium") {
                display: inline-block;
            }

            // status icon
            + .icon {
                position: absolute;
                top: get($sizes, "border");
                right: get($sizes, "border");

                &.done {
                    color: palette("state", "success");
                }

                &.error {
                    color: palette("state", "warning");
                }

                &.locked {
                    opacity: 0.4;
                }
            }
        }
    }
}
</style>
